import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CultureService } from 'ngx-myia-localization';
import { DashboardRoutePath } from '@shared';

@Component({
    template: ''
})
export class PortalAppRedirectComponent {
    constructor(activatedRoute: ActivatedRoute, cultureService: CultureService, router: Router) {
        activatedRoute.queryParams.subscribe(queryParams => {
            //https://stackoverflow.com/questions/36687164/pass-parameters-in-angular2-routeconfig-redirectto
            const cultureQS = queryParams['culture'];
            if (cultureQS) {
                cultureService.use(cultureQS).subscribe();
            }
            // redirect to dashboard
            router.navigate([`/${DashboardRoutePath}`]);
        });
    }

}
