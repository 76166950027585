import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PortalAppModule } from './app';
// set AOT mode flag for mode detection (dynamic plugins loading use this flag)
window['isAOT'] = true;
// depending on the env mode, enable prod mode or add debugging modules
if (process.env.ISPROD) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(PortalAppModule);

