
        <div class="portalAppRoot" trackMouseFocus>
            <div class="loadingModule" *ngIf="loadingModule">{{'Loading'|trans}}</div>
            <div class="portalViewContent" [ngClass]="{loading: loadingModule}">
                <router-outlet></router-outlet>
            </div>
            <context-menu-holder></context-menu-holder>
            <status-bar *ngIf="loadingModule"></status-bar>
        </div>
        <ng-template let-dialog dialogIdentifier="confirmationDialog">
            <div class="dialogMessage">{{dialog.dialogData}}</div>
            <div class="dialogButtons">
                <button type="button" class="dialogButtonSecondary dialogButton" (click)="dialog.close()">{{'No'| trans}}</button>
                <button type="button" class="dialogButtonPrimary dialogButton" (click)="dialog.close(true)">{{'Yes'| trans}}</button>
            </div>
        </ng-template>
    