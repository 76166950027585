import { Component, HostBinding, HostListener, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APP_VIEWCONTAINERREF_CHANGED, EmitterService, lazyModuleLoadingChanged } from 'ngx-myia-core';
import { CultureService, LocalizationService } from 'ngx-myia-localization';
import { PWAUpdateService, StatusBarManager } from '@shared';

@Component({
    selector: 'portal-app',
    styleUrls: ['./portalApp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="portalAppRoot" trackMouseFocus>
            <div class="loadingModule" *ngIf="loadingModule">{{'Loading'|trans}}</div>
            <div class="portalViewContent" [ngClass]="{loading: loadingModule}">
                <router-outlet></router-outlet>
            </div>
            <context-menu-holder></context-menu-holder>
            <status-bar *ngIf="loadingModule"></status-bar>
        </div>
        <ng-template let-dialog dialogIdentifier="confirmationDialog">
            <div class="dialogMessage">{{dialog.dialogData}}</div>
            <div class="dialogButtons">
                <button type="button" class="dialogButtonSecondary dialogButton" (click)="dialog.close()">{{'No'| trans}}</button>
                <button type="button" class="dialogButtonPrimary dialogButton" (click)="dialog.close(true)">{{'Yes'| trans}}</button>
            </div>
        </ng-template>
    `
})
export class PortalAppComponent implements OnInit {

    @HostBinding('class.appEl') hostClass = true;

    loadingModule: boolean;

    // inject PWAUpdateService to check new app versions
    constructor(private _changeDetectorRef: ChangeDetectorRef, vcRef: ViewContainerRef, pwaUpdateService: PWAUpdateService, private _router: Router, private _statusBarManager: StatusBarManager, private titleService: Title, private _cultureService: CultureService, private _localizationService: LocalizationService) {

        // notify other components about root app view container (e.g. ModalDialog, GestureModule)
        EmitterService.getValueChangedEvent<ViewContainerRef>(APP_VIEWCONTAINERREF_CHANGED).next(vcRef);
        lazyModuleLoadingChanged(this._router, 'portal').subscribe(loading => {
            this.loadingModule = loading;
            this._statusBarManager.showBusyLine(loading, 'portalLazyLoading');
            this._changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
        this._cultureService.onChange.subscribe(() => {
            this.titleService.setTitle(this._localizationService.translate('MyMyia'));
        });
        this._localizationService.get('MyMyia').subscribe(title => {
            this.titleService.setTitle(title);
        });
    }

    @HostListener('dragenter', ['$event'])
    onDragEnterBody($event: any) {
        return this.cancelDragOperation($event);
    }

    @HostListener('dragover', ['$event'])
    onDragOverBody($event: any) {
        return this.cancelDragOperation($event);
    }

    private cancelDragOperation($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
        $event.dataTransfer.dropEffect = 'none'; // disable drop by default
        return false;
    }
}
